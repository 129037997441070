
export default Object.freeze({
    PASSWORD_MIN_LENGTH: 8,
    MIN_BIRTH_YEAR: 1900,

    // User status
    USER_STATUS_NEW: 0,
    USER_STATUS_ACTIVE: 1,
    USER_STATUS_INACTIVE: 2,
    USER_STATUS_ADMIN_REVIEW: 3,
    USER_STATUS_REJECTED: 4,

    // Dependent Status
    DEPENDENT_STATUS_NEW: 0,
    DEPENDENT_STATUS_APPROVED: 1,
    DEPENDENT_STATUS_DIVORCED: 2,
    DEPENDENT_STATUS_ADMIN_REVIEW: 3,
    DEPENDENT_STATUS_DECEASED: 4,
    DEPENDENT_STATUS_REJECTED: 5,

    // Claim Status
    CLAIM_STATUS_NEW: 0,
    CLAIM_STATUS_APPROVED: 1,
    CLAIM_STATUS_PAID: 2,
    CLAIM_STATUS_ADMIN_REVIEW: 3,
    CLAIM_STATUS_REJECTED: 4,

    // Invoice Status
    INVOICE_STATUS_ADMIN_REVIEW: 0,
    INVOICE_STATUS_OPEN: 1,
    INVOICE_STATUS_PAID: 2,

    // Payment Status
    PAYMENT_STATUS_ADMIN_REVIEW: 0,
    PAYMENT_STATUS_VERIFIED: 1,
    PAYMENT_STATUS_VOID: 2,
    PAYMENT_STATUS_REJECTED: 3,

    // Payment Methods
    PAYMENT_METHOD_BANK_DEPOSIT: 'Wells Fargo Bank Deposit',
    PAYMENT_METHOD_ZELLE: 'Zelle',
    PAYMENT_METHOD_BANK_TRANSFER_WF: 'Wells Fargo Bank Transfer',
    PAYMENT_METHOD_CHECK: 'Check',
    PAYMENT_METHOD_BANK_TRANSFER_NON_WF: 'Non-Wells Fargo Bank Transfer',

    // User membership qualification
    QUALIFICATION_TYPE_KALENJIN: 1,
    QUALIFICATION_TYPE_SPOUSE: 2,
    QUALIFICATION_TYPE_RECOMMENDED: 3,

    getUserStatusMap() {
        return [
            'New',
            'Active',
            'Inactive',
            'Admin Review',
            'Rejected'
        ]
    },

    getDependentStatusMap() {
        return [
            'New',
            'Approved',
            'Divorced',
            'Admin Review',
            'Deceased',
            'Rejected'
        ]
    },

    getClaimStatusMap() {
        return [
            'New',
            'Approved',
            'Paid',
            'Admin Review',
            'Rejected'
        ]
    },

    getInvoiceStatusMap() {
        return [
            'Admin Review',
            'Open',
            'Paid'
        ]
    },

    getPaymentStatusMap() {
        return [
            'Admin Review',
            'Verified',
            'Void',
            'Rejected'
        ]
    },

    getPaymentMethods() {
        return [
            this.PAYMENT_METHOD_BANK_TRANSFER_WF,
            this.PAYMENT_METHOD_BANK_DEPOSIT,
            this.PAYMENT_METHOD_ZELLE,
            this.PAYMENT_METHOD_CHECK,
            this.PAYMENT_METHOD_BANK_TRANSFER_NON_WF
        ]
    },
})
